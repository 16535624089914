<template>
  <v-app>
    <v-card elevation="0">
      <v-card-title>
        Administrative functions
      </v-card-title>
      <v-container class="mb-3">
        <v-row>
          <v-col cols="7">
            <v-card>
              <v-row>
                <v-col cols="1">
                  <v-icon
                    class="mr-8 ml-2 mt-1"
                    size="52"
                    color="success"
                  >
                    mdi-file-document-arrow-right-outline
                  </v-icon>
                </v-col>
                <v-col cols="8">
                  <v-card-title class="text-h5">
                    Process Order
                  </v-card-title>
                </v-col>
                <v-col cols="2">
                  <v-card-actions>
                    <router-link :to="{ name: 'ProcessOrder' }">
                      <v-btn
                        color="primary"
                        class="mt-2 ml-5"
                      >
                        Go
                      </v-btn>
                    </router-link>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mb-3">
        <v-row>
          <v-col cols="7">
            <v-card>
              <v-row>
                <v-col cols="1">
                  <v-icon
                    class="mr-8 ml-2 mt-1"
                    size="52"
                    color="success"
                  >
                    mdi-eye-remove-outline
                  </v-icon>
                </v-col>
                <v-col cols="8">
                  <v-card-title class="text-h5">
                    Omit Codes
                  </v-card-title>
                </v-col>
                <v-col cols="2">
                  <v-card-actions>
                    <router-link :to="{ name: 'OmitCodes' }">
                      <v-btn
                        color="primary"
                        class="mt-2 ml-5"
                      >
                        Go
                      </v-btn>
                    </router-link>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mb-3">
        <v-row>
          <v-col cols="7">
            <v-card>
              <v-row>
                <v-col cols="1">
                  <v-icon
                    class="mr-8 ml-2 mt-1"
                    size="52"
                    color="success"
                  >
                    mdi-refresh-auto
                  </v-icon>
                </v-col>
                <v-col cols="8">
                  <v-card-title class="text-h5">
                    Auto Processed Orders
                  </v-card-title>
                </v-col>
                <v-col cols="2">
                  <v-card-actions>
                    <router-link :to="{ name: 'AutoProcessOrder' }">
                      <v-btn
                        color="primary"
                        class="mt-2 ml-5"
                      >
                        Go
                      </v-btn>
                    </router-link>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mb-3">
        <v-row>
          <v-col cols="7">
            <v-card>
              <v-row>
                <v-col cols="1">
                  <v-icon
                    class="mr-8 ml-2 mt-1"
                    size="52"
                    color="success"
                  >
                    mdi-card-account-mail-outline
                  </v-icon>
                </v-col>
                <v-col cols="8">
                  <v-card-title class="text-h5">
                    Manage Mailing Address
                  </v-card-title>
                </v-col>
                <v-col cols="2">
                  <v-card-actions>
                    <router-link :to="{ name: 'MailingAddressManagement' }">
                      <v-btn
                        color="primary"
                        class="mt-2 ml-5"
                      >
                        Go
                      </v-btn>
                    </router-link>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <v-container class="mb-3">
        <v-row>
          <v-col cols="7">
            <v-card>
              <v-row>
                <v-col cols="1">
                  <v-icon
                    class="mr-8 ml-2 mt-1"
                    size="52"
                    color="success"
                  >
                    mdi-file-download-outline
                  </v-icon>
                </v-col>
                <v-col cols="8">
                  <v-card-title class="text-h5">
                    Download Processed Jobs
                  </v-card-title>
                </v-col>
                <v-col cols="2">
                  <v-card-actions>
                    <router-link :to="{ name: 'DownloadProcessedJobs' }">
                      <v-btn
                        color="primary"
                        class="mt-2 ml-5"
                      >
                        Go
                      </v-btn>
                    </router-link>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-app>
</template>

<script>

export default {
  name: 'Admin',

  data () {
    return {
      loading: false
    }
  }
}
</script>
