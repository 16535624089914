var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-card",
        { attrs: { elevation: "0" } },
        [
          _c("v-card-title", [
            _vm._v("\n      Administrative functions\n    ")
          ]),
          _c(
            "v-container",
            { staticClass: "mb-3" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-8 ml-2 mt-1",
                                      attrs: { size: "52", color: "success" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  mdi-file-document-arrow-right-outline\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "text-h5" },
                                    [
                                      _vm._v(
                                        "\n                  Process Order\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: { name: "ProcessOrder" }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2 ml-5",
                                              attrs: { color: "primary" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Go\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "mb-3" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-8 ml-2 mt-1",
                                      attrs: { size: "52", color: "success" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  mdi-eye-remove-outline\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "text-h5" },
                                    [
                                      _vm._v(
                                        "\n                  Omit Codes\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: { to: { name: "OmitCodes" } }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2 ml-5",
                                              attrs: { color: "primary" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Go\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "mb-3" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-8 ml-2 mt-1",
                                      attrs: { size: "52", color: "success" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  mdi-refresh-auto\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "text-h5" },
                                    [
                                      _vm._v(
                                        "\n                  Auto Processed Orders\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: { name: "AutoProcessOrder" }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2 ml-5",
                                              attrs: { color: "primary" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Go\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "mb-3" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-8 ml-2 mt-1",
                                      attrs: { size: "52", color: "success" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  mdi-card-account-mail-outline\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "text-h5" },
                                    [
                                      _vm._v(
                                        "\n                  Manage Mailing Address\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "MailingAddressManagement"
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2 ml-5",
                                              attrs: { color: "primary" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Go\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            { staticClass: "mb-3" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "7" } },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "1" } },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-8 ml-2 mt-1",
                                      attrs: { size: "52", color: "success" }
                                    },
                                    [
                                      _vm._v(
                                        "\n                  mdi-file-download-outline\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c(
                                    "v-card-title",
                                    { staticClass: "text-h5" },
                                    [
                                      _vm._v(
                                        "\n                  Download Processed Jobs\n                "
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "2" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: {
                                              name: "DownloadProcessedJobs"
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticClass: "mt-2 ml-5",
                                              attrs: { color: "primary" }
                                            },
                                            [
                                              _vm._v(
                                                "\n                      Go\n                    "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }